
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import Add from '@/views/coupon/classadd.vue'
import Edit from '@/views/coupon/classedit.vue'

export default {
	components: { Add, Edit },
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({
				moduleName: '券码类别',
				// moduleApi: '/Ctx/PortalCode/Class/',
				moduleType: 'full',
			}),
			list: reactive({data: []}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
	}
}
